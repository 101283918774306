import { render, staticRenderFns } from "./SavedAllocationList.vue?vue&type=template&id=38259b04&scoped=true&"
import script from "./SavedAllocationList.vue?vue&type=script&lang=ts&"
export * from "./SavedAllocationList.vue?vue&type=script&lang=ts&"
import style0 from "./SavedAllocationList.vue?vue&type=style&index=0&id=38259b04&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38259b04",
  null
  
)

export default component.exports