
















































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { DSADgroupcropvarieties, DSADgroup, SendMail } from "@/store/models/user";
import { FiscalYear } from "@/store/models/meta";
import { Demand } from "@/store/models/DemandAndSupply";
import {
  CropVarietyStore,
  CropStore,
  DemandSupplyStore,
  MetaStore,
  UserStore
} from "@/store/modules";
import { MetaInfo } from "vue-meta";
import { convertADtoBS, nepaliDate } from "@/store/modules/dateConverter";

@Component({
  components: {},
  metaInfo(): MetaInfo {
    return {
      title: "Supply List",
      titleTemplate: "%s - SQCC",
      meta: [
        { charset: "utf-8" },
        { name: "keyword", content: "Sqcc demand list" },
        {
          name: "description",
          content: "List of Demands of seeds",
          vmid: "demandList"
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchInput: string = "";
  fiscalYearId: number | null = null;
  groupId: number | null = null;
  cropVarietyId: number | null = null;
  seedTypeId: number | null = null;
  districtId: number | null = null;
  pageNum: number = 1;
  clusterId: number = 0;
  language: string = "";
  loading: boolean = false;
  dialogVisible: boolean = false;
  companyusers: any = [];
  emailmessage: string = "";
  createdon: string = "";
  transaction: string = "";
  group = {} as DSADgroup;
  grouplist: DSADgroup[] = [] as DSADgroup[];
  fiscalYearList: FiscalYear[] = [] as FiscalYear[];
  groupcropvarietieslist: DSADgroupcropvarieties[] = [] as DSADgroupcropvarieties[];
  transactionName: string | null = null;
  mailobj: SendMail = {} as SendMail;
  transactiontype = [
    {
      id: 1,
      name: "supply"
    },
    {
      id: 2,
      name: "demand"
    },
    {
      id: 3,
      name: "allocation"
    },
    {
      id: 4,
      name: "distribution"
    }
  ];

  get dataList() {
    return UserStore.SendMail;
  }

  get dsadgroup() {
    this.grouplist = UserStore.DSADgroup;
    return this.grouplist;
  }

  get FiscalYearList() {
    return MetaStore.FiscalYear;
  }

  nepaliDate(date: string) {
    return nepaliDate(date);
  }

  sendmaildetail(obj1: any) {
    this.mailobj = obj1;
    // this.emailmessage = obj2;
    // this.transaction = obj3;
    // this.createdon = obj4;
    this.dialogVisible = true;
  }

  getvarieties() {
    this.cropVarietyId = null;
    this.group = this.grouplist.filter(x => x.groupid == Number(this.groupId))[0];
    if (this.group) {
      this.groupcropvarietieslist = Object.assign(this.group.varietyname);
    }
  }

  search() {
    let query: any = { page: 1 };
    this.pageNum = 1;
    if (this.fiscalYearId != null) {
      query["fiscalYearId"] = String(this.fiscalYearId);
    }
    if (this.groupId != null) {
      query["groupId"] = String(this.groupId);
    }
    if (this.cropVarietyId != null) {
      query["cropVarietyId"] = String(this.cropVarietyId);
    }
    if (this.transactionName != null) {
      query["transactionName"] = String(this.transactionName);
    }
    this.routerReplacer();
    UserStore.sendmaillogs(query);
  }

  clearSearch() {
    let query: any = { page: 1 };
    this.pageNum = 1;
    this.fiscalYearId = null;
    this.transactionName = null;
    this.groupId = null;
    this.cropVarietyId = null;
    this.routerReplacer();
    UserStore.sendmaillogs(query);
  }

  routerReplacer() {
    let query: any = {};
    if (this.pageNum) {
      query["page"] = String(this.pageNum);
    }
    if (this.fiscalYearId != null) {
      query["fiscalYearId"] = String(this.fiscalYearId);
    }
    if (this.groupId != null) {
      query["groupId"] = String(this.groupId);
    }
    if (this.cropVarietyId != null) {
      query["cropVarietyId"] = String(this.cropVarietyId);
    }
    if (this.transactionName != null) {
      query["transactionName"] = String(this.transactionName);
    }
    if (this.$route.name) {
      this.$router.replace({
        name: this.$route.name,
        query: query
      }).catch(error => {});
    }
    return query;
  }

  async handlePageChange(val: number) {
    this.pageNum = val;
    this.loading = true;
    let query = this.routerReplacer();
    await UserStore.sendmaillogs(query);
    this.loading = false;
  }

  async created() {
    await UserStore.dsadgroup();
    await MetaStore.getAllFiscalYear();

    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }
    if (this.$route.query.fiscalYearId) {
      this.fiscalYearId = Number(this.$route.query.fiscalYearId);
    }
    if (this.$route.query.transactionName) {
      this.transactionName = String(this.$route.query.transactionName);
    }
    if (this.$route.query.groupId) {
      this.groupId = Number(this.$route.query.groupId);
    }
    if (this.$route.query.cropVarietyId) {
      this.cropVarietyId = Number(this.$route.query.cropVarietyId);
    }
    let query = this.routerReplacer();
    this.loading = true;
    await UserStore.sendmaillogs(query);
    this.group = this.grouplist.filter(x => x.groupid == Number(this.groupId))[0];
    if (this.group) {
      this.groupcropvarietieslist = Object.assign(this.group.varietyname);
    }
    this.loading = false;
  }
}
