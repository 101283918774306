






















































































































































import { Vue, Component } from "vue-property-decorator";
import { UserStore } from "@/store/modules";
import { User } from "@/store/models/user";
import GeneralUserList from "@/views/Admin/Components/GeneralUserList.vue";
import AdminUserList from "@/views/Admin/Components/AdminUserList.vue";
import SeedCompanyList from "@/views/Admin/Components/SeedCompanyList.vue";
import DemandCompanyList from "@/views/Admin/Components/DemandCompanyList.vue";
import { eventHub } from "@/utils/eventHub";

@Component({
  components: {
    GeneralUserList,
    AdminUserList,
    SeedCompanyList,
    DemandCompanyList
  },
  metaInfo: {
    title: "User List",
    titleTemplate: "%s - SQCC",
    meta: [
      { charset: "utf-8" },
      { name: "keyword", content: "Sqcc user list" },
      {
        name: "description",
        content: "List of users of SQCC",
        vmid: "userList"
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ]
  }
})
export default class userList extends Vue {
  dialogVisible: boolean = false;
  innerVisible: boolean = false;
  loading: boolean = false;
  selectedIndex = -1;
  data: User = {} as User;
  activeName: string = "general";
  searchString: string = "";
  formVisible: any = {
    demandCompany: false,
    seedCompany: false,
    general: false,
    admin: false
  };
  demandCompany: string = "Demand Organization";
  demandSupplyCompany: string = "Supply/Demand Organization";

  get dataList() {
    return UserStore.Users;
  }

  get adminList() {
    return UserStore.AdminUsers;
  }

  get generalUserList() {
    return UserStore.GeneralUsers;
  }

  get activeTab() {
    return this.activeName;
  }

  set activeTab(tab: string) {
    this.activeName = tab;
    this.$router
      .replace({
        name: "UserList",
        query: { activeTab: this.activeName }
      })
      .catch(error => {});
  }

  addUser() {
    this.$validator.reset();
    if (this.activeTab === "seedCompany" || this.activeTab === "demandCompany") {
      this.$router.push({
        name: "Seedcomregister"
      }).catch(error => {});
    } else {
      this.formVisible = {
        demandCompany: this.activeTab === "demandCompany",
        seedCompany: this.activeTab === "seedCompany",
        general: this.activeTab === "general",
        admin: this.activeTab === "admin"
      };
      eventHub.$emit("clearUserData");
    }
  }

  editForm(index: number) {
    this.$validator.reset();
    this.dialogVisible = true;
    this.selectedIndex = index;
    if (this.activeTab == "admin") {
      this.data = Object.assign({}, this.adminList.results[index]);
    } else if (this.activeTab == "general") {
      this.data = Object.assign({}, this.generalUserList.results[index]);
    } else {
      this.data = Object.assign({}, this.dataList[index]);
    }
  }

  async submit() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      console.log("submit");
      this.dialogVisible = false;
    } else {
      this.$message.error("Form  Invalid");
    }
  }
  changeDemandCompanyTabName(obj: string) {
    this.demandCompany = "Demand Organization - (" + obj + ")";
    if (obj == undefined) {
      this.demandCompany = "Demand Organization";
    }
  }

  changeSupplyDemandCompanyTabName(obj: string) {
    this.demandSupplyCompany = "Supply/Demand Organization - (" + obj + ")";
    if (obj == undefined) {
      this.demandSupplyCompany = "Supply/Demand Organization";
    }
  }

  private created() {
    UserStore.getGeneralUserList();
    UserStore.getAdminUserList();
    UserStore.getSeedCompanyUserList();
    UserStore.getConsumerCompanyUserList();
    eventHub.$on("changeDemandCompanyTabName", this.changeDemandCompanyTabName);
    eventHub.$on("changeSupplyDemandCompanyTabName", this.changeSupplyDemandCompanyTabName);
  }

  mounted() {
    if (this.$route.query.activeTab) {
      this.activeTab = String(this.$route.query.activeTab);
    }
  }

  async handlePrevNextClick(val: any, list: string, approved?: any) {
    this.loading = true;
    if (list == "admin") {
      await UserStore.getAdminUserList({
        searchString: this.searchString,
        page: val
      });
    } else if (list == "general") {
      await UserStore.getGeneralUserList({
        searchString: this.searchString,
        page: val
      });
    } else if (list == "seedCompany") {
      await UserStore.getSeedCompanyUserList({
        approved: approved,
        searchString: this.searchString,
        page: val
      });
    } else if (list == "demandCompany") {
      await UserStore.getConsumerCompanyUserList({
        approved: approved,
        searchString: this.searchString,
        page: val
      });
    } else {
      console.log(list);
    }
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    this.loading = false;
  }

  searchText() {
    this.demandCompany = "Demand Organization";
    this.demandSupplyCompany = "Supply/Demand Organization";
    UserStore.getAdminUserList({
      searchString: this.searchString,
      page: 1
    });
    UserStore.getGeneralUserList({
      searchString: this.searchString,
      page: 1
    });
    UserStore.getSeedCompanyUserList({
      searchString: this.searchString,
      page: 1
    });
    UserStore.getConsumerCompanyUserList({
      searchString: this.searchString,
      page: 1
    });
  }

  onDeleteClick(index: number, id: number) {
    let user = this.dataList[index].name;

    console.log("delete this in backend");
    // this.$confirm(
    //   "This will permanently delete the user " +
    //     user +
    //     " if he has not created any plans. Continue?",
    //   "Warning",
    //   {
    //     confirmButtonText: "Delete",
    //     confirmButtonClass: "el-button--danger",
    //     cancelButtonText: "Cancel",
    //     showClose: false,
    //     type: "warning"
    //   }
    // )
    //   .then(async () => {
    //     console.log("delete this in backend");
    //   })
    //   .catch(() => {
    //     this.$message({
    //       type: "info",
    //       message: "Delete cancelled"
    //     });
    //   });
  }
}
