



















import { Vue, Component, Prop } from "vue-property-decorator";
import { CaptionFile } from "@/store/models/cropVariety";

@Component
export default class UploadElement extends Vue {
  @Prop({ default: () => [] })
  fileList!: any[];

  @Prop({ default: [] as File[] })
  file!: File[];

  handleAfterUpload(event: any) {
    this.fileList.splice(0, 1);
    this.file.splice(0, 1);
    this.file.push(event.raw);
    this.fileList.push(event);
  }

  handleExceed(file: any, fileList: any) {
    this.fileList.pop();
    this.fileList.push(file[0]);
    this.file.pop();
    this.file.push(file[0]);
  }

  beforeRemove(file: any, filelist: any) {
    return this.$confirm(`Do you want to delete ${file.name} ?`);
  }

  handleAfterRemove(file: any, filelist: any) {
    let index = this.fileList.findIndex(x => x.uid === file.uid);
    if (index !== -1) {
      if (this.fileList[index].id) {
        this.$emit("deleteFile", this.fileList[index].id);
      }
      this.fileList.splice(index, 1);
      this.file.splice(index, 1);
    }
  }
}
