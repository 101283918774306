




















import { Vue, Component } from "vue-property-decorator";
import { JWTStore } from "@/store/modules";

@Component
export default class roleList extends Vue {
  get dataList() {
    return JWTStore.UserInRole;
  }

  editForm(i: number) {
    this.$emit("editRole", i);
  }
}
